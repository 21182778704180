<script>
import { cashboxService } from "../../../../helpers/backend/cashbox.service"
import { cashboxPlacementService } from "../../../../helpers/backend/cashbox_placement.service"
import { machineService } from "../../../../helpers/backend/machine.service"
import { dateMixin } from '../../../../helpers/date'

export default {
  mixins: [dateMixin],
  data() {
    return {
      form: {
        id: null,
        cashbox_id: null,
        machine_id: null,
        place_at: null,
        take_at: null,
      },
      cashbox_options: [{ text: "Select One", value: null }],
      machine_options: [{ text: "Select One", value: null }],
      busy: false,
      show: true,
      showDismissibleAlert: false,
      error: "",
      active: false,
    }
  },
  created() {
    if (!this.cashbox.id) {
      this.$router.back()
    }
    this.form.cashbox_id = this.cashbox.id
    this.fetchData()
  },
  computed: {
    isUpdateState() {
      return this.form.id != null
    },
    cashbox() {
      return this.$route.params
    },
    formPlaceAtTime: {
      get() {
        return this.getTime(this.form.place_at)
      },
      set(newValue) {
        this.form.place_at = this.setTime(this.form.place_at, newValue)
      }
    },
    formTakeAtTime: {
      get() {
        return this.getTime(this.form.take_at)
      },
      set(newValue) {
        this.form.take_at = this.setTime(this.form.take_at, newValue)
      }
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      this.busy = true
      cashboxPlacementService.createOrUpdate(this.form).then(
        (data) => {
          if (data != null) {
            this.reset()
            this.$emit("refresh")
            this.showDismissibleAlert = true
            this.error = ""
            this.setActive(false)
          }
          this.busy = false
        },
        (error) => {
          this.showDismissibleAlert = true
          this.error =
            "Failed to Insert/Update Cashbox Placement. Error : " + error
          this.busy = false
        }
      )
    },
    reset() {
      // Reset our form values
      this.form.id = null
      this.form.cashbox_id = null
      this.form.machine_id = null
      this.form.place_at = null
      this.form.take_at = null
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.showDismissibleAlert = false
      this.error = ""
      this.$nextTick(() => {
        this.show = true
      })
    },
    onReset(event) {
      event.preventDefault()
      this.setActive(false)
      this.reset()
    },
    setActive(b, outside_triger) {
      this.active = b
      if (outside_triger) return
      this.$emit("active_change", b)
    },
    initUpdate(id) {
      this.setActive(true)

      this.$nextTick(() => {
        this.reset()
        this.busy = true
        cashboxPlacementService.getById(id).then(
          (data) => {
            if (data.placement != null) {
              this.form.id = data.placement.id
              this.form.cashbox_id = data.placement.cashbox_id
              this.form.machine_id = data.placement.machine_id
              this.form.place_at = data.placement.place_at
              this.form.take_at = data.placement.take_at
            } else {
              this.showDismissibleAlert = true
              this.error =
                "Failed to get cashbox placement to update. Error : cashbox placement not found"
            }
            this.busy = false
          },
          (err) => {
            this.showDismissibleAlert = true
            this.error = "Failed to get cashbox placement to update. Error : " + err
            this.busy = false
          }
        )
      })
    },
    invokeCreate() {
      this.setActive(true)
    },
    fetchData() {
      // Filter product if already exist in receive
      cashboxService.getAll().then(
        (data) => {
          this.cashbox_options = this.cashbox_options.concat(
            data.cashboxes.map((item) => {
              return { text: item.number, value: item.id }
            })
          )
        },
        (err) => {
          this.showDismissibleAlert = true
          this.error = "Failed to get cashboxes. Error : " + err
        }
      )

      machineService.getAllMachine().then(
        (data) => {
          this.machine_options = this.machine_options.concat(
            data.machines.map(function (item) {
              return { text: item.name, value: item.id }
            })
          )
        },
        (err) => {
          this.showDismissibleAlert = true
          this.error = "Failed to get machines. Error : " + err
        }
      )
    }
  },
};
</script>


<template>
  <div v-if="this.active">
    <b-alert
      :variant="error == '' ? 'success' : 'danger'"
      dismissible
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
    >
      <div v-if="error != ''">
        {{ error }}
      </div>
      <div v-else>Success perform Insert/Update action</div>
    </b-alert>
    <div class="card">
      <div class="card-body">
        <div>
          <b-form @submit="onSubmit" @reset="onReset" v-if="show" ref="form">
            <b-overlay :show="busy" rounded="lg" opacity="0.6">
              <b-form-group
                id="input-group-cashbox"
                label="Cashbox:"
                label-for="input-cashbox"
              >
                <b-form-select
                  id="input-cashbox"
                  v-model="form.cashbox_id"
                  :options="cashbox_options"
                  required
                  readonly
                >
                </b-form-select>
              </b-form-group>
              <b-form-group
                id="input-group-machine"
                label="Machine :"
                label-for="input-machine"
              >
                <b-form-select
                  id="input-machine"
                  v-model="form.machine_id"
                  :options="machine_options"
                  required
                >
                </b-form-select>
              </b-form-group>

              <b-row>
                <b-col>
                  <b-form-group
                    id="input-group-place-at-date"
                    label="Placed At :"
                    label-for="input-place-at-date"
                  >
                    <b-form-datepicker
                      id="input-place-at-date"
                      v-model="form.place_at"
                      placeholder="Place At"
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    id="input-group-place-at-time"
                    label="Time : "
                    label-for="input-place-at-time"
                  >
                    <b-form-timepicker
                      id="input-place-at-time"
                      v-model="formPlaceAtTime"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <b-form-group
                    id="input-group-take-at-date"
                    label="Taken At :"
                    label-for="input-take-at-date"
                  >
                    <b-form-datepicker
                      id="input-take-at-date"
                      v-model="form.take_at"
                      placeholder="Take At"
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    id="input-group-take-at-time"
                    label="Time : "
                    label-for="input-take-at-time"
                  >
                    <b-form-timepicker
                      id="input-take-at-time"
                      v-model="formTakeAtTime"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-button class="mr-2" type="submit" variant="primary">
                {{ isUpdateState ? "Update" : "Submit" }}
              </b-button>
              <b-button type="reset" variant="danger">Cancel</b-button>
            </b-overlay>
          </b-form>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <b-form-group>
      <b-button variant="primary" @click="invokeCreate">Create new</b-button>
    </b-form-group>
  </div>
</template>



