import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

function format(date, format = 'DD MMMM YYYY HH:mm') {
    const dayjsInstance = dayjs(date)
    return dayjsInstance.isValid() ? dayjsInstance.format(format) : ''
}

function parseTime(date, format = 'HH:mm:ss') {
    return dayjs(date, format)
}

export const dateHelper = {
    format,
    parseTime
}

export const dateMixin = {
    methods: {
        formatDate(date) {
            return format(date)
        },
        getTime(date) {
            return format(date, 'HH:mm:ss')
        },
        parseTime(date) {
            return parseTime(date)
        },
        setTime(date, value) {
            const time = this.parseTime(value, 'HH:mm:ss')
            let currentTime = dayjs(date)

            currentTime = currentTime.hour(time.hour())
            currentTime = currentTime.minute(time.minute())
            currentTime = currentTime.second(time.second())

            return currentTime.format()
        }
    }
}