<script>
import { cashboxPlacementService } from '../../../../helpers/backend/cashbox_placement.service'
import { dateMixin } from '../../../../helpers/date'

export default {
  mixins: [dateMixin],
  data() {
    return {
      data: [],
      loading: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50],
      filter: null,
      filterOn: [],
      sortBy: "place_at",
      sortDesc: true,
      fields: [
        { key: "cashbox.number", sortable: true, label: "Cashbox" },
        { key: "machine.name", sortable: true, label: "Machine" },
        { key: "place_at", sortable: true, label: "Placed At" },
        { key: "take_at", sortable: true, label: "Taken At" },
        { key: "cash", sortable: true, label: "Cash" },
        { key: "action" }
      ],
       fields_detail: [
        { key: "transaction_created_at", label: "Transaction Date" },
        // { key: "transaction_order_id", label: "Order ID" },
        { key: "product_name", label: "Product Name" },
        { key: "quantity", label: "Quantity" },
        { key: "price", label: "Price" }
      ],
      showDismissibleAlert: false,
      active: true,
    }
  },
  computed: {
    rows() {
      return this.data.length
    },
    cashbox() {
      return this.$route.params
    },
  },
  created() {
    this.fetchData()
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.data.length
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    setActive(b) {
      this.active = b
    },
    fetchData() {
      this.error = null
      this.loading = true
      this.showDismissibleAlert = false
      cashboxPlacementService.getAll().then(
        data => {
          this.data = data.placements.filter(item => item.cashbox_id == this.cashbox.id)
          this.loading = false
        },
        err => {
          this.error = "Failed to fetch cashbox placement data. Error : " + err + "  Dismiss to refresh"
          this.loading = false
          this.showDismissibleAlert = true
        }
      )
      cashboxPlacementService.getDetailByCashboxId(this.cashbox.id).then(
        data => {
          this.data.forEach(cashbox => {
            cashbox.detail = data.placement_detail.filter(item => item.cashbox_placement_id == cashbox.id)
            cashbox.cash = 0
            cashbox.detail.forEach(detail => {
              cashbox.cash += detail.price
            });
          });
        }, 
        err => {
          this.error = "Failed to fetch cashbox placement data. Error : " + err + "  Dismiss to refresh"
          this.loading = false
          this.showDismissibleAlert = true
        }
      )
    },
    onDelete(id) {
      if (confirm("Do you really want to delete this instance, this action can't be undone?")) {
        cashboxPlacementService.deleteById(id).then(
          () => {
            this.fetchData()
          },
          err => {
            this.error = "Failed to delete cashbox placement. Error : " + err
            this.showDismissibleAlert = true
          }
        )
      }
    },
    onUpdate(id) {
      this.$emit('onEdit', id)
    }
  }
};
</script>

<template>
  <div v-if="this.active">
    <b-alert
      variant="danger"
      dismissible
      fade
      :show="showDismissibleAlert"
      @dismissed="fetchData"
    >
      {{ error }}
    </b-alert>
    <div class="card">
      <div class="card-body">
        <div class="row mt-4">
          <div class="col-sm-12 col-md-6">
            <div id="tickets-table_length" class="dataTables_length">
              <label class="d-inline-flex align-items-center">
                Show&nbsp;
                <b-form-select
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                ></b-form-select
                >&nbsp;entries
              </label>
            </div>
          </div>
          <!-- Search -->
          <div class="col-sm-12 col-md-6">
            <div
              id="tickets-table_filter"
              class="dataTables_filter text-md-right"
            >
              <label class="d-inline-flex align-items-center">
                Search:
                <b-form-input
                  v-model="filter"
                  type="search"
                  class="form-control form-control-sm ml-2"
                ></b-form-input>
              </label>
            </div>
          </div>
          <!-- End search -->
        </div>
        <div class="table-responsive">
          <b-table
            :items="data"
            :fields="fields"
            responsive="sm"
            :per-page="perPage"
            :current-page="currentPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template v-slot:cell(place_at)="row">
              {{ formatDate(row.value) }}
            </template>
            <template v-slot:cell(take_at)="row">
              {{ formatDate(row.value) }}
            </template>
            <template v-slot:cell(action)="row">
              <a size="sm" @click="row.toggleDetails" class="mr-3 text-primary"
                v-b-tooltip.hover
                data-toggle="tooltip"
                title="View Detail">
                <i class="mdi mdi-eye font-size-18"></i>
              </a>

              <a
                @click="onUpdate(row.item.id)"
                class="mr-3 text-primary"
                v-b-tooltip.hover
                data-toggle="tooltip"
                title="Edit"
              >
                <i class="mdi mdi-pencil font-size-18"></i>
              </a>
              <a
                @click="onDelete(row.item.id)"
                class="text-danger"
                v-b-tooltip.hover
                title="Delete"
              >
                <i class="mdi mdi-trash-can font-size-18"></i>
              </a>
            </template>

            <template #row-details="row">
              <b-table
                :items="row.item.detail"
                :fields="fields_detail"
              >
                <template v-slot:cell(transaction_created_at)="row">
                  {{ formatDate(row.value) }}
                </template>
              </b-table>
            </template>
            
          </b-table>
        </div>
        <div class="row">
          <div class="col">
            <div class="dataTables_paginate paging_simple_numbers float-right">
              <ul class="pagination pagination-rounded mb-0">
                <!-- pagination -->
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                ></b-pagination>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>