import { authHeader } from './auth-header';
import axiosInstance from "./client"

export const machineService = {
    getAllMachine,
    getAllMachineType,
    getAllPlanogramsByMachineID,
    createOrUpdate,
    getMachineByID,
    deleteMachineByID,
    getLastMachineSerialNumber,
    updatePlanogramData,
};


function getAllMachine(filter) {
    var filterQuery = "";
    if (filter != null) {
        filterQuery = "?";
        for (const [key, value] of Object.entries(filter)) {
            filterQuery +=key+"="+value+"&";
          }
    }
    const requestOptions = {
        url : `/api/v1/user/machine`+filterQuery,
        method: 'GET',
        headers: authHeader()
    };
    return axiosInstance(requestOptions)
}

function getMachineByID(machineId) {
    const requestOptions = {
        url : `/api/v1/user/machine/` + machineId,
        method: 'GET',
        headers: authHeader()
    };
    return axiosInstance(requestOptions)
}

function getAllMachineType() {
    const requestOptions = {
        url : `/api/v1/user/machine_type`,
        method: 'GET',
        headers: authHeader()
    };
    return axiosInstance(requestOptions)
}

function getAllPlanogramsByMachineID(id) {
    const requestOptions = {
        url : `/api/v1/user/machine_planogram/` + id,
        method: 'GET',
        headers: authHeader()
    };
    return axiosInstance(requestOptions)
}

function createOrUpdate(jsonData) {
    // console.log(jsonData);
    // let formData = new FormData();

    // for ( var key in jsonData ) {
    //     if (jsonData[key] != null)
    //         formData.append(key, jsonData[key]);
    // }
    // let rMethod = jsonData["id"] != null  ? "PUT" : "POST"
    // const requestOptions = {
    //     url : `/api/v1/user/machine`,
    //     method: rMethod,
    //     data: formData,
    //     headers: authHeader()
    // };
    let rMethod = jsonData["id"] != null  ? "PUT" : "POST"
    let setHeader = authHeader()
    setHeader['Content-Type'] = 'application/json'
    const requestOptions = {
        url : `/api/v1/user/machine`,
        method: rMethod,
        headers: setHeader,
        data: JSON.stringify(jsonData)
    };

    return axiosInstance(requestOptions);
}


function deleteMachineByID(machineId) {
    const requestOptions = {
        url : `/api/v1/user/machine/` + machineId,
        method: 'DELETE',
        headers: authHeader()
    };
    return axiosInstance(requestOptions);
}

function getLastMachineSerialNumber() {
    const requestOptions = {
        url : `/api/v1/user/machine/last_serial_number`,
        method: 'GET',
        headers: authHeader()
    };
    return axiosInstance(requestOptions)
}

function updatePlanogramData(jsonData) {
    let rMethod = 'PUT'
    let setHeader = authHeader()
    setHeader['Content-Type'] = 'application/json'
    const requestOptions = {
        url : `/api/v1/user/planogram`,
        method: rMethod,
        headers: setHeader,
        data: JSON.stringify(jsonData)
    };

    return axiosInstance(requestOptions);
}